/* Container styling */
.plans-container {
  background: url('../assets/pricing_bg.png') no-repeat center center/cover;
  padding: 300px 10px;
  text-align: center;
  margin-top: -120px;
  border-radius: 15px;

}

/* Header section */
.plans-header .sub-heading {
  font-size: 18px;
  color: #ffc04d;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 600;
  letter-spacing: 1px;
}

.plans-header .main-heading {
  font-size: 36px;
  margin-bottom: 30px;
  color: #000;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Grid layout for plans sections */
.plans-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 85%;
  margin: 0 auto;
}

/* Individual section styling */
.plans-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 12px;
  background: #ffffff;
  color: #000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex-wrap: wrap;
  justify-content: space-between;
}

.plans-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Left Section */
.left-section {
  width: 48%;
  margin-right: 20px;
  margin-top: -px;
  text-align: center; /* Center all content horizontally */
  position: relative;
}

/* Plan name moved upper */
.left-section h3 {
  font-size: 26px;
  margin-bottom: 15px;
  color: #b40508;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.left-section .price {
  font-size: 30px;
  font-weight: 900; /* Made price bold */
  color: #6d0202;
  margin-bottom: 15px;
  text-align: center; /* Ensure price is centered */
}

.left-section .description {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  font-style: italic;
}

.left-section .features-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.left-section .features-list li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

/* Button styling */
.buy-button {
  display: inline-block;
  margin-top: 15px;
  padding: 12px 30px;
  background: linear-gradient(90deg, #B40508 0%, #F36637 100%);
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center; /* Centered button */
  margin-left: auto;
  margin-right: auto; /* Horizontally center the button */
}

.buy-button:hover {
  background: linear-gradient(90deg, #F36637 0%, #B40508 100%);
  transform: scale(1.05);
}

/* Right Section */
.right-section {
  width: 48%;
  text-align: left;
  margin-top: 20px;
}

.right-section .bullet-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.right-section .bullet-list li {
  display: flex;
  align-items: center;
  margin-bottom: 25px; /* Increased gap between bullets */
  font-size: 15px;
  color: #555;
  font-weight: bold; /* Made text bold */
}

.right-section .bullet {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 50%;
  background: linear-gradient(180deg, #EF3237 0%, #F36637 100%);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .plans-grid {
    grid-template-columns: 1fr;
  }

  .plans-section {
    flex-direction: column;
    text-align: center;
  }

  .left-section,
  .right-section {
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }

  .buy-button {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .plans-container {
    padding: 150px 20px;
  }

  .plans-header .main-heading {
    font-size: 30px;
  }

  .left-section h3 {
    font-size: 22px;
  }

  .right-section .bullet-list li {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .plans-container {
    padding: 100px 20px;
  }

  .plans-header .sub-heading {
    font-size: 14px;
  }

  .plans-header .main-heading {
    font-size: 24px;
  }

  .buy-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .left-section h3 {
    font-size: 18px;
  }

  .left-section .features-list li {
    font-size: 14px;
  }

  .right-section .bullet-list li {
    font-size: 13px;
  }
}
