.footer {
  background-color: #253247;
  color: #ffffff;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  margin-top: -50px;
  border-top: 5px solid #EF3237;
}

@media (min-width: 1024px) {
  .footer {
    margin-top: 0;
    padding: 40px 20px;
  }
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
}

.footer-column {
  flex: 1 1 220px;
  max-width: 320px;
  margin-bottom: 20px;
}

.footer-subscription {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1c2b3a;
  border-radius: 8px;
}

.footer-subscription h3 {
  font-size: 26px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 600;
}

.subscription-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 100%;
}

.email-input {
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 300px;
  outline: none;
  transition: all 0.3s ease;
}

.email-input::placeholder {
  color: #aaa;
}

.email-input:focus {
  border: 2px solid #EF3237;
  box-shadow: 0 0 8px rgba(239, 50, 55, 0.6);
}

.subscribe-button {
  padding: 15px 30px;
  background-color: #EF3237;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #d92a31;
  transform: translateY(-2px);
}

.footer-logo {
  max-width: 120px;
  margin: 0 auto 15px auto;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.1);
}

.elevator-pitch {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  color: #ccc;
  margin-bottom: 20px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Styling for Additional Links similar to Social Media Links */
.footer-links li button {
  background: none;
  border: none;
  color: #EF3237; /* Same as social media links */
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 0;
}

.footer-links li button:hover {
  color: #d92a31; /* Hover color matches social media links */
  transform: translateY(-2px); /* Adds a slight lift effect */
}

.footer-links li {
  text-align: left;
  margin-bottom: 12px;
  margin-left: 110px; /* Maintain the existing alignment */
}

/* Social Media Links Styling */
.footer-links li a {
  color: #EF3237;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.2s ease;
}

.footer-links li a:hover {
  color: #d92a31;
  transform: translateY(-2px);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  font-size: 14px;
  width: 100%;
  color: #aaa;
}

.footer-bottom-left {
  text-align: left;
  flex: 1;
}

.footer-bottom-right {
  text-align: right;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.footer-bottom-right a {
  color: #fff !important; /* White color for default state */
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-bottom-right a:hover {
  color: #EF3237; /* Red color on hover */
}