/* General header styles */
header {
  background-color: transparent;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  flex-wrap: nowrap; /* Ensure everything stays on the same line */
}

/* Logo styles */
.logo {
  width: 75px;
  height: auto;
  margin-right: 40px;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.1); /* Add a scaling effect on hover */
}

/* Navigation styles */
nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

/* Button styles for menu items */
nav ul li button {
  background-color: transparent;
  color: black;
  font-size: 14px; /* Reduced font size to 14px */
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  transition: color 0.3s ease-in-out, transform 0.2s ease;
  position: relative;
}

nav ul li button:hover {
  color: #f36637;
  transform: translateY(-3px); /* Lift effect on hover */
}

nav ul li button.active {
  color: #f36637; /* Active color */
}

/* Navigation button sizes and styles */
.nav-btn {
  padding: 12px 25px;
  background: linear-gradient(90deg, #b40508 0%, #f36637 120.86%);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-size: 14px; /* Reduced font size to 14px */
  font-weight: bold;
  margin-left: 150px;
}

.nav-btn:hover {
  background: linear-gradient(90deg, #f36637 0%, #b40508 120.86%);
  transform: scale(1.05); /* Slight scaling effect on hover */
}

/* Remove background from all buttons */
nav ul li button {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}

.hamburger-menu div {
  width: 30px;
  height: 3px;
  background-color: black;
  transition: transform 0.3s, opacity 0.3s;
}

.hamburger-menu span.close-menu {
  font-size: 24px;
  color: black;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-20%);
  cursor: pointer;
}

.hamburger-menu.active div:nth-child(1),
.hamburger-menu.active div:nth-child(2),
.hamburger-menu.active div:nth-child(3) {
  display: none; /* Hide bars when menu is active */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  nav ul {
    display: none;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  nav.open ul {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align menu items */
  }

  nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    background: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    padding: 2rem 1rem;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  nav.open {
    transform: translateX(0);
    background-color: #ab2c02;
    width: 30%;
 
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-btn {
    align-self: left;
    margin-right: 150px;
    margin-top: 50px;
  }
}

@media (max-width: 570px) {
  .logo {
    width: 50px;
  }

  nav ul li a {
    font-size: 14px;
  }

  .nav-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Section Scroll Behavior */
section {
  scroll-margin-top: 100px;
}

html {
  scroll-behavior: smooth;
}

/* Close Button Styles */
.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 24px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  z-index: 1100;
}

.close-btn:hover {
  color: #f36637;
  transform: scale(1.1); /* Optional hover effect */
}
