/* Default for desktop view */
.contact-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-bottom: 0; /* Adjust to remove unnecessary bottom margin */
  height: auto;
}

/* Contact Info Section */
.contact-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
  align-items: flex-start; /* Align the items at the top */
  width: 100%; /* Ensure the section takes full width */
}

.contact-info-left {
  width: 43%;  /* Adjust width for better space allocation */
  height: 550px; 
  background-color: white;
  border: 1px solid #ffcccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box; /* Ensures padding is inside the element's width */
}

.contact-info-right {
  width: 55%;  /* Adjust width for better space allocation */
  padding-left: 15px;
  box-sizing: border-box; /* Ensures padding is inside the element's width */
}

/* Form section inside contact-info-right */
.contact-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.select-subject-title {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
  text-align: left;
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-row input {
  width: 48%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form textarea {
  width: 96%;
}

.contact-form select {
  width: 100%;
}

.bulletin-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.bulletin {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bulletin span {
  margin-right: 10px;
}

.bulletin.active {
  color: green;
}

textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Submit button */
.submit-button {
  padding: 10px 15px;
  background: linear-gradient(90deg, #F36637 0%, #EF3237 100%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s;
  width: fit-content;
  margin-left: auto;
}

.submit-button:hover {
  background: linear-gradient(90deg, #EF3237 0%, #F36637 100%);
}

/* Map Section */
.contact-map {
  margin-top: 20px;
  width: 100%;
}

/* Responsive Design - Adjustments for smaller screens */
@media (max-width: 1024px) {
  .contact-container {
    min-height: 800px; /* Adjusted height for medium screens */
    padding: 20px;
    margin-bottom: 40px;
  }

  .contact-info-left {
    width: 48%; /* Adjust width to 48% on medium screens */
  }

  .contact-info-right {
    width: 48%; /* Adjust width to 48% on medium screens */
    padding-left: 0;
  }

  .contact-form {
    left: 0;
  }
}

@media (max-width: 768px) {
  .contact-info-left, .contact-info-right {
    width: 100%;
    padding-left: 0;
  }

  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .submit-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 10px;
  }

  .contact-info-left, .contact-info-right {
    width: 100%;
  }

  .contact-form {
    left: 0;
  }

  .form-row {
    flex-direction: column;
    gap: 8px;
  }

  .contact-form textarea {
    width: 100%;
  }
}

/* Contact item styling */
.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 50px;
}
