/* About Us Section */
.about-us-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    width: 100vw;
}

.about-us-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
}

/* Left Column */
.about-left-column {
    flex: 1;
    padding-right: 50px;
    padding-top: 250px;
}

.about-subheading {
    font-size: 1.3rem;
    color: #f36637;
    margin-bottom: 15px;
    text-align: center;
    margin-top: -200px;
}

.about-heading {
    font-size: 2rem !important;
    margin-bottom: 20px;
    color: #333;
    text-align: left;
    margin-top: 50px;
    line-height: 1.3;
}

.about-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 50px;
}

.about-btn {
    padding: 15px 30px;
    background: linear-gradient(90deg, #B40508 0%, #F36637 100%);
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s;
}

.about-btn:hover {
    background: linear-gradient(90deg, #F36637 0%, #B40508 100%);
}

/* Right Column */
.about-right-column {
    flex: 1;
    background-image: url('../assets/aboutus_bg.png');
    background-size: 200%;
    background-position: center center;
    background-repeat: no-repeat;
    height: 400px;
    margin-left: 1px;
    padding-left: 0;
}

.about-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 50px;
}

/* Responsive Design - Adjustments for smaller screens */
@media (max-width: 1024px) {
    .about-us-section {
        padding: 50px 20px;
    }

    .about-us-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .about-left-column {
        padding-right: 0;
        padding-top: 50px;
        margin-top: 500px;
    }

    .about-subheading {
        font-size: 1.1rem;
    }

    .about-heading {
        font-size: 2rem;
    }

    .about-text {
        font-size: 0.9rem;
        margin-bottom: 20px;
    }

    .about-btn {
        padding: 12px 25px;
    }

    .about-right-column {
        height: 300px;
        background-size: 150%;
    }

    .about-image {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .about-us-section {
        padding: 40px 15px;
    }

    .about-left-column {
        padding-top: 30px;
        margin-top: 450px;
    }

    .about-subheading {
        font-size: 1rem;
        margin-top: 1px;
    }

    .about-heading {
        font-size: 1.8rem;
    }

    .about-text {
        font-size: 0.85rem;
        margin-bottom: 15px;
    }

    .about-btn {
        padding: 10px 20px;
    }

    .about-right-column {
        height: 250px;
        background-size: 120%;
    }

    .about-image {
        max-width: 90%;
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .about-us-section {
        padding: 30px 10px;
        margin-top: 50px;
    }

    .about-us-container {
        width: 100%;
        margin-top: 450px;
    }

    .about-left-column {
        padding-top: 20px;
    }

    .about-subheading {
        font-size: 0.9rem;
        margin-top: 50px;
    }

    .about-heading {
        font-size: 1.6rem;
    }

    .about-text {
        font-size: 0.8rem;
        margin-bottom: 10px;
    }

    .about-btn {
        padding: 8px 15px;
    }

    .about-right-column {
        height: 200px;
        background-size: 100%;
    }

    .about-image {
        max-width: 80%;
        margin-top: 10px;
    }
}
