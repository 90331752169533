/* src/styles/Signup.css */
.signup-container {
  display: flex;
  min-height: 90vh;
  flex-wrap: nowrap; /* Prevent stacking unless on smaller screens */
  justify-content: space-between; /* Align sections to their respective sides */

}

.left-side {
  width: 40%;
  background: linear-gradient(0deg, rgba(243, 102, 55, 0.2), rgba(243, 102, 55, 0.2));
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-image {
  width: 100%; /* Ensures the image takes up full available width */
  max-width: none; /* Remove max-width limitation */
  height: auto; /* Maintain aspect ratio */
  transform: scale(1.1);
  margin-bottom: 50px;
}

.right-side {
  width: 60%;
  padding: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap; /* Allows input fields to wrap on smaller screens */
}

.input-column {
  width: 48%;
  margin-right: 4%;
}

.input-column:last-child {
  margin-right: 0;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

button {
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-button {
  background: linear-gradient(90deg, #b40508 0%, #f36637 120.86%);
  color: white;
  font-size: 1rem;
  margin-top: 20px;
}

.terms-checkbox {
  margin-top: 15px;
}

.terms-checkbox label {
  font-size: 0.9rem;
}

.terms-checkbox span {
  color: #007bff;
  text-decoration: underline;
}

.google-signup {
  margin-top: 20px;
  text-align: center;
}

.google-button {
  background-color: #4285f4;
  color: white;
  font-size: 1rem;
  padding: 10px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.google-button span {
  margin-left: 10px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column; /* Stack sections vertically on smaller screens */
  }

  .left-side, .right-side {
    width: 100%; /* Make both sections full width */
  }

  .signup-image {
    transform: scale(1.2); /* Reduce scale for smaller screens */
  }

  .input-column {
    width: 100%; /* Full width for input fields */
    margin-right: 0; /* Remove margin between columns */
  }

  h2 {
    font-size: 1.5rem; /* Smaller heading font size */
  }

  p {
    font-size: 1rem; /* Smaller paragraph font size */
  }

  .signup-button, .google-button {
    font-size: 0.9rem; /* Adjust button text size for mobile */
    padding: 10px; /* Adjust button padding */
  }

  .terms-checkbox label {
    font-size: 0.8rem; /* Smaller text for terms */
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.2rem; /* Even smaller heading font size */
  }

  p {
    font-size: 0.9rem; /* Smaller paragraph font size */
  }

  .signup-button, .google-button {
    font-size: 0.8rem; /* Smaller button text size */
    padding: 8px; /* Adjust padding */
  }

  .terms-checkbox label {
    font-size: 0.7rem; /* Even smaller terms text */
  }
}
