/* General Blog Section Styles */
.blog-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
    margin-top: 120px;
    text-align: center;
}

.blog-heading {
    font-size: 24px;
    color: black;
    margin-bottom: 20px;
    text-align: center;
}

.blog-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    height: 450px;
}

/* Blog Card Styles */
.blog-card {
    position: relative;
    width: 345px;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.blog-card-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.blog-card-content {
    padding: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}

.blog-description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 15px;
    line-height: 1.5;
}

.read-more-btn {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

/* Responsive Design - Adjustments for smaller screens */
@media (max-width: 1024px) {
    .blog-container {
        justify-content: center;
        align-items: center;
    }

    .blog-card {
        width: 100%;
        max-width: 345px;
    }
}

@media (max-width: 768px) {
    .blog-container {
        justify-content: center;
        align-items: center;
    }

    .blog-card {
        width: 100%;
        max-width: 345px;
    }
}

@media (max-width: 480px) {
    .blog-container {
        justify-content: center;
        align-items: center;
    }

    .blog-card {
        width: 100%;
        max-width: 345px;
    }
}
