/* src/styles/Hero.css */
body, html {
  margin-top: -65px;
}

.hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 50px 0;
  background-image: url('../assets/Rectangle.png');
  margin-top: -100px;
  height: 100vh;
}

.hero-content {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Center content vertically */
  width: 100%;
  flex-wrap: wrap;
}

.left-column {
  flex: 1;
  max-width: 50%;
  text-align: left;
  padding-left: 120px;
  margin-top: -150px; /* Move content in the left column up */
}

.hero-heading {
  font-size: 3rem;
  margin-top: 350px;
  margin-bottom: 50px;
  line-height: 1.2;
  color: #1f0505;
}

.hero-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
}
.blinking {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.hero-btn {
  background: linear-gradient(90deg, #B40508 0%, #F36637 120.86%);
  color: white;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  align-self: flex-start;
}

.hero-btn:hover {
  background: linear-gradient(90deg, #F36637 0%, #B40508 120.86%);
}

.right-column {
  flex: 1;
  max-width: 47%;
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Align image vertically */
  padding-left: 20px;
  position: relative;
  top: 50px; /* Move the image down slightly */
}

.hero-img {
  max-width: 100%; /* Ensure image is responsive */
  height: auto;
  position: relative;
  display: block;
  margin-top: 100px;
}

/* Responsive Design - Adjustments for smaller screens */
@media (max-width: 1024px) {
  .hero {
    height: 80vh;
    background-position: top center;
    margin-top: -250px;
  }

  .hero-content {
    flex-direction: column; /* Stack columns vertically */
    align-items: center;
  }

  .left-column, .right-column {
    max-width: 100%;
    margin-bottom: 60px;
    padding-left: 20px;
  }

  .hero-heading {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }

  .hero-text {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .hero-btn {
    font-size: 0.9rem;
    padding: 12px 25px;
  }

  .hero-img {
    width: 85%; /* Adjust image width for smaller screens */
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 75vh;
    background-position: top center;
    margin-top: -250px;
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .hero-heading {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .hero-text {
    font-size: 1rem;
    margin-top: -35px;
  }

  .hero-btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  .hero-img {
    width: 80%; /* Adjust image width further for smaller screens */
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .hero {
    height: 70vh;
    background-position: top center;
    margin-top: -300px;
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hero-heading {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .hero-text {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .hero-btn {
    font-size: 0.8rem;
    padding: 8px 18px;
  }

  .hero-img {
    width: 100%;
    margin-top: 20px;
  }
}
