
:root {
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --accent-color: #4a4a4a;
    --text-color: #333;
   
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
h2 {
    font-size: 50px !important;
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    
}

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    
}

/* Banner Section */
.banner-two {
    position: relative;
    background: linear-gradient(90deg, #b40508 0%, #f36637 120.86%);
    padding: 40px 0;
    overflow: hidden;
}

.banner-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    align-items: center;
}

.banner-two__left-content {
    text-align: left;
}

.banner-two__left-content h2 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.banner-two__left-content-text1 ul {
    display: flex;
    list-style: none;
    gap: 15px;
    margin-bottom: 20px;
}

.banner-two__left-content-text1 .img-box img {
    max-width: 60px;
    border-radius: 50%;
}

.counter-box {
    background-color: var(--background-color);
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
}

.button-style-1 {
    display: inline-block;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 12px 25px;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
    margin-top: 20px;
}

.button-style-1:hover {
    background-color: var(--accent-color);
    transform: translateY(-5px);
}

/* Services Section */
.services-two {
    padding: 80px 0;
    /* background-color: var(--secondary-color); */
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
}

.services-two__single {
    background: linear-gradient(90deg, #b40508 0%, #f36637 120.86%);
    padding: 30px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.services-two__single:hover {
    transform: translateY(-10px);
}

.services-two__single-img {
    position: relative;
    margin-bottom: 20px;
}

.services-two__single-img img {
    max-width: 100%;
    border-radius: 10px;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .banner-grid,
    .services-grid {
        grid-template-columns: 1fr;
    }

    .banner-two__left-content-text1 ul {
        flex-wrap: wrap;
    }

    .banner-two__left-content h2 {
        font-size: 2rem;
    }
}

/* Interactive Demo Styles */
.demo-section {
    /* background-color: var(--secondary-color); */
    padding: 50px 0;
}

.demo-form {
    max-width: 500px;
    margin: 0 auto;
    background-color: var(--background-color);
    padding: 30px;
    border-radius: 15px;
    /* box-shadow: 0 10px 30px rgba(0,0,0,0.1); */
}

.demo-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
}

.demo-button {
    width: 100%;
    padding: 12px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.demo-button:hover {
    background-color: var(--accent-color);
}

#abt{
    /* Global Styles */
:root {
    --primary-color: #007acc;
    --secondary-color: #ff6f61;
    --bg-color: #f9f9f9;
    --text-color: #333;
    --hover-bg: #eaf6ff;
    --transition-speed: 0.3s;
    --font-family: "Poppins", Arial, sans-serif;
  }
  
  body {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #ffffff !important;
    color: var(--text-color);
  }
  
  /* Container Styles */
  .about-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 12px;
    background: var(--bg-color);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    animation: fadeIn var(--transition-speed) ease-in-out;
  }
  
  /* Header Section */
  .about-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-subtitle {
    font-size: 1.2rem;
    color: var(--primary-color);
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .about-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .about-title .highlight {
    color: var(--secondary-color);
    position: relative;
  }
  
  .about-title .highlight::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background: var(--primary-color);
    border-radius: 2px;
    animation: underline var(--transition-speed) linear;
  }
  
  /* Description */
  .about-description {
    font-size: 1rem;
    line-height: 1.8;
    color: #555;
    text-align: justify;
    margin-bottom: 30px;
  }
  
  /* How It Works Section */
  .how-it-works {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .section-heading {
    text-align: center;
    font-size: 1.8rem;
    color: #d0cece;
    margin-bottom: 20px;
  }
  .section-text{
    color: #d0cece;
  }
  .steps-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .step-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
    border-radius: 8px;
    background: var(--hover-bg);
    transition: transform var(--transition-speed), box-shadow var(--transition-speed);
  }
  
  .step-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .step-icon {
    font-size: 2rem;
    color: var(--secondary-color);
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background var(--transition-speed);
  }
  
  .step-icon:hover {
    background: var(--secondary-color);
    color: #fff;
  }
  
  .step-content h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 8px;
  }
  
  .step-content p {
    font-size: 0.95rem;
    line-height: 1.5;
    color: #555;
    margin: 0;
  }
  
  /* Footer Section */
  .about-footer {
    text-align: center;
    margin-top: 30px;
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes underline {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-title {
      font-size: 1.8rem;
    }
  
    .steps-list {
      grid-template-columns: 1fr;
    }
  }
/* How It Works Section */
.how-it-works {
    background: #f9f9f9; /* Light background for contrast */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.step-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
    border-radius: 8px;
    background: var(--hover-bg);
    transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.step-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    background: #eaf6ff; /* Change background on hover */
}

.step-icon {
    font-size: 2rem;
    color: var(--secondary-color);
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background var(--transition-speed);
}

.step-icon:hover {
    background: var(--secondary-color);
    color: #fff;
}  
}
